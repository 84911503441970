// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-digital-garden-git-mdx": () => import("./../../../content/pages/digital-garden/git.mdx" /* webpackChunkName: "component---content-pages-digital-garden-git-mdx" */),
  "component---content-pages-digital-garden-heroku-mdx": () => import("./../../../content/pages/digital-garden/heroku.mdx" /* webpackChunkName: "component---content-pages-digital-garden-heroku-mdx" */),
  "component---content-pages-digital-garden-mdx": () => import("./../../../content/pages/digital-garden.mdx" /* webpackChunkName: "component---content-pages-digital-garden-mdx" */),
  "component---content-pages-digital-garden-netlify-mdx": () => import("./../../../content/pages/digital-garden/netlify.mdx" /* webpackChunkName: "component---content-pages-digital-garden-netlify-mdx" */),
  "component---content-pages-digital-garden-postgresql-mdx": () => import("./../../../content/pages/digital-garden/postgresql.mdx" /* webpackChunkName: "component---content-pages-digital-garden-postgresql-mdx" */),
  "component---content-pages-digital-garden-react-mdx": () => import("./../../../content/pages/digital-garden/react.mdx" /* webpackChunkName: "component---content-pages-digital-garden-react-mdx" */),
  "component---content-pages-digital-garden-vscode-mdx": () => import("./../../../content/pages/digital-garden/vscode.mdx" /* webpackChunkName: "component---content-pages-digital-garden-vscode-mdx" */),
  "component---src-templates-categories-list-template-js": () => import("./../../../src/templates/categories-list-template.js" /* webpackChunkName: "component---src-templates-categories-list-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-not-found-template-js": () => import("./../../../src/templates/not-found-template.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-projects-template-js": () => import("./../../../src/templates/projects-template.js" /* webpackChunkName: "component---src-templates-projects-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-tags-list-template-js": () => import("./../../../src/templates/tags-list-template.js" /* webpackChunkName: "component---src-templates-tags-list-template-js" */)
}

